import useInitSentry from "@/core/hook/useInitSentry"
import { useCookieConsent } from "@/organization/tracking/cookie-consent/CookieConsentStore"
import React from "react"

const SentryProvider: React.FC = ({ children }) => {
  const { hasConsented } = useCookieConsent()

  useInitSentry(hasConsented)

  return <>{children}</>
}

export default SentryProvider
