import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  NotificationsContextQuery,
  NotificationsContextQuery$data,
} from "@/core/context/__generated__/NotificationsContextQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useInterval from "@utils/hook/useInterval"
import { SECOND_IN_MS } from "@utils/time/timeConstants"
import { ReactNode, createContext, useCallback, useContext } from "react"
import { graphql } from "relay-runtime"

// Refetch notificaitons every minute
const NOTIFICATION_POLL_INTERVAL_MS = 60 * SECOND_IN_MS

// Infer the element type from an array type
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export type NotificationNode = ArrayElement<
  NonNullable<
    NonNullable<NotificationsContextQuery$data["node"]>["viewerNotifications"]
  >["edges"]
>["node"]

export type NotificationFilter = Pick<
  NotificationNode,
  "entityId" | "kind" | "productId"
> & {
  feedId: GlobalID
  contentUsageId: GlobalID
  folderId: GlobalID
  // To filter unread notifications by kinds, filtering by a single kind is disabled when this is provided
  kinds: string[]
  productIds: GlobalID[]
  productsOnly: boolean
  navSectionId: GlobalID
  eventsAppId: GlobalID
}

const NotificationsContext = createContext<{
  notifications: NotificationNode[]
  totalUnreadCount: number
  getUnreadNotifications: (filter: Partial<NotificationFilter>) => NotificationNode[]
  refetch: () => void
}>({
  notifications: [],
  totalUnreadCount: 0,
  getUnreadNotifications: () => [],
  refetch: () => null,
})

export function useNotificationsContext() {
  const context = useContext(NotificationsContext)
  if (!context)
    throw new Error(
      "useNotificationsContext used outside of NotificationsProvider context"
    )
  return context
}

interface NotificationsProviderProps {
  children: ReactNode
}

function NotificationsProvider({ children }: NotificationsProviderProps) {
  const activeOrganization = useActiveOrganization()

  const [{ node }, refetch] = Relay.useRefetchableQuery<NotificationsContextQuery>(
    graphql`
      query NotificationsContextQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            viewerNotifications(isUnread: true) {
              totalCount
              edges {
                node {
                  id
                  productId
                  readAt
                  kind
                  entityId
                  content {
                    type
                    post {
                      id
                      feedId
                      feed {
                        app {
                          navFolderId
                          navSectionId
                        }
                      }
                    }
                  }
                  submission {
                    id
                    contentUsageId
                    contentUsage {
                      productApp {
                        navFolderId
                      }
                    }
                  }
                  webFormSubmission {
                    id
                    contentUsageId
                  }
                  occurrence {
                    appId
                  }
                  product {
                    navSectionId
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
    },
    { refetchInBackground: true }
  )

  useInterval(() => {
    refetchNotifications()
  }, NOTIFICATION_POLL_INTERVAL_MS)

  /** Refetch notifications in context */
  const refetchNotifications = useCallback(() => {
    if (!activeOrganization) return
    refetch({ id: activeOrganization.id }, { inBackground: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization?.id])

  const notificationsValue = Relay.connectionToArray(node?.viewerNotifications)

  const getUnreadNotifications = useCallback(
    (target: Partial<NotificationFilter>): NotificationNode[] => {
      const {
        feedId,
        contentUsageId,
        kinds,
        folderId,
        productIds,
        productsOnly,
        navSectionId,
        eventsAppId,
        ...filteredTarget
      } = target

      return notificationsValue.filter((unread) => {
        // To filter event app notifications
        if (eventsAppId && eventsAppId !== unread.occurrence?.appId) return false

        // To filter post notifications for a feed
        if (feedId && feedId !== unread.content?.post?.feedId) return false

        // To filter notifications for a nav folder
        if (
          folderId &&
          folderId !== unread.content?.post?.feed.app.navFolderId &&
          folderId !== unread.submission?.contentUsage.productApp?.navFolderId
        )
          return false

        // To filter notifications for a content usage
        if (
          contentUsageId &&
          contentUsageId !== unread.submission?.contentUsageId &&
          contentUsageId !== unread.webFormSubmission?.contentUsageId
        )
          return false

        if (productIds && !productIds.some((id) => id === unread.productId)) return false
        if (productsOnly && !unread.productId) return false

        if (
          navSectionId &&
          navSectionId !== unread.content?.post?.feed.app.navSectionId &&
          navSectionId !== unread.product?.navSectionId
        )
          return false

        // Make sure the partial target matches every single key in the unread notification object
        // If kinds is provided, make sure the unread.kind is in it (overrides single kind filter)
        return (
          Object.entries(filteredTarget).every(
            ([key, value]) => unread[key as keyof NotificationNode] === value
          ) && (kinds ? kinds.includes(unread.kind) : true)
        )
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsValue]
  )

  return (
    <NotificationsContext.Provider
      value={{
        notifications: notificationsValue,
        totalUnreadCount: notificationsValue.length,
        getUnreadNotifications,
        refetch: refetchNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
