/**
 * @generated SignedSource<<f6071ec1d191bcf1643d544421506401>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DomainMappingStatus = "failed_certificate" | "failed_certificate_refresh" | "initial" | "needs_certificate" | "needs_verification" | "verified" | "%future added value";
export type CoreIndexSourceQuery$variables = {
  domain: string;
};
export type CoreIndexSourceQuery$data = {
  readonly domain: {
    readonly id: string;
    readonly isDefault: boolean;
    readonly isPrimary: boolean;
    readonly organization: {
      readonly creationDatetime: string;
      readonly id: string;
      readonly name: string;
      readonly primaryDomain: string;
      readonly slug: string;
    };
    readonly status: DomainMappingStatus;
  } | null;
  readonly requiresEmailVerification: boolean;
  readonly viewer: {
    readonly email: string;
    readonly id: string;
    readonly lastActiveOrganizationMembership: {
      readonly organization: {
        readonly primaryDomain: string;
      };
    } | null;
  } | null;
};
export type CoreIndexSourceQuery = {
  response: CoreIndexSourceQuery$data;
  variables: CoreIndexSourceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domain"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryDomain",
  "storageKey": null
},
v3 = {
  "alias": "domain",
  "args": [
    {
      "kind": "Variable",
      "name": "domain",
      "variableName": "domain"
    }
  ],
  "concreteType": "DomainMapping",
  "kind": "LinkedField",
  "name": "domainMapping",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPrimary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationDatetime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresEmailVerification",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoreIndexSourceQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "lastActiveOrganizationMembership",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoreIndexSourceQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "lastActiveOrganizationMembership",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ef1f183d0309b6673baaeac623d54742",
    "id": null,
    "metadata": {},
    "name": "CoreIndexSourceQuery",
    "operationKind": "query",
    "text": "query CoreIndexSourceQuery(\n  $domain: String!\n) {\n  domain: domainMapping(domain: $domain) {\n    id\n    isPrimary\n    status\n    isDefault\n    organization {\n      id\n      name\n      slug\n      primaryDomain\n      creationDatetime\n    }\n  }\n  viewer {\n    id\n    email\n    lastActiveOrganizationMembership {\n      organization {\n        primaryDomain\n        id\n      }\n      id\n    }\n  }\n  requiresEmailVerification\n}\n"
  }
};
})();

(node as any).hash = "f923552c1c220dbcc191ae702975950e";

export default node;
