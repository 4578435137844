import { OnboardingChecklistSectionKind } from "@/admin/onboarding-checklist/__generated__/AdminOnboardingChecklistSectionFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  OnboardingChecklistContextCompleteItemMutation,
  OnboardingChecklistItemKind,
  ReportingOnboardingChecklistActivityActionSource,
} from "@/core/context/__generated__/OnboardingChecklistContextCompleteItemMutation.graphql"
import {
  OnboardingChecklistContextHideChecklistMutation,
  UpdateOrganizationMemberOnboardingChecklistVisibilityInput,
} from "@/core/context/__generated__/OnboardingChecklistContextHideChecklistMutation.graphql"
import {
  OnboardingChecklistContextQuery,
  OnboardingChecklistContextQuery$data,
} from "@/core/context/__generated__/OnboardingChecklistContextQuery.graphql"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import { NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import usePermissions from "@utils/hook/usePermissions"
import { createContext, useCallback, useContext, useState } from "react"
import { graphql } from "relay-runtime"

type OnboardingChecklistSectionNode = NodeFromConnection<
  NonNullable<OnboardingChecklistContextQuery$data["node"]>["onboardingChecklistSections"]
>

type OnboardingChecklistItemNode = NodeFromConnection<
  OnboardingChecklistSectionNode["items"]
>

type OnboardingChecklistContextValue = {
  activeState: OnboardingChecklistActiveState
  setActiveSectionId: (id: string | null) => void
  setActiveItemId: (id: string | null) => void
  completionPercentage: number
  nextIncompleteItem: OnboardingChecklistItemNode | null
  sections: OnboardingChecklistSectionNode[]
  completeOrIgnoreChecklistItem: (
    kind: OnboardingChecklistItemKind,
    actionSource?: ReportingOnboardingChecklistActivityActionSource
  ) => Promise<void>
  isChecklistSectionCompletedOrMissing: (kind: OnboardingChecklistSectionKind) => boolean
  hideChecklistForm: FormStore<
    UpdateOrganizationMemberOnboardingChecklistVisibilityInput,
    OnboardingChecklistContextHideChecklistMutation
  > | null
  shouldHideChecklist: boolean
}

const OnboardingChecklistContext = createContext<OnboardingChecklistContextValue>({
  activeState: {
    sectionId: null,
    itemId: null,
  },
  setActiveSectionId: () => null,
  setActiveItemId: () => null,
  completionPercentage: 0,
  sections: [],
  nextIncompleteItem: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  completeOrIgnoreChecklistItem: async () => {},
  isChecklistSectionCompletedOrMissing: () => true,
  hideChecklistForm: null,
  shouldHideChecklist: false,
})

export function useOnboardingChecklistContext() {
  const context = useContext(OnboardingChecklistContext)

  if (context === null) {
    throw new Error(
      "useOnboardingChecklistContext must be used within a OnboardingChecklistProvider"
    )
  }

  return context
}

type OnboardingChecklistActiveState = {
  sectionId: string | null
  itemId: string | null
}

function OnboardingChecklistProvider(props: { children: React.ReactNode }) {
  const { children } = props

  const activeOrganization = useActiveOrganization()
  const permissions = usePermissions(activeOrganization)
  const canRead = permissions?.has("onboarding_checklist.read")

  const { node } = Relay.useSkippableLazyLoadQuery<OnboardingChecklistContextQuery>(
    graphql`
      query OnboardingChecklistContextQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            viewerMembership {
              hideOnboardingChecklist
            }
            onboardingChecklistSections {
              totalCount
              edges {
                node {
                  id
                  content {
                    kind
                  }
                  ...AdminOnboardingChecklistSectionFragment
                  items {
                    edges {
                      node {
                        id
                        content {
                          kind
                          name
                        }
                        completedAt
                        organizationOnboardingChecklistSectionId
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
    },
    {
      // Skip fetching the checklist if the user does not have the required permission to view it
      // to speed up the initial render
      skip: !canRead,
    }
  )

  const form = useFormStore<OnboardingChecklistContextCompleteItemMutation>(
    graphql`
      mutation OnboardingChecklistContextCompleteItemMutation(
        $input: CompleteOrganizationOnboardingChecklistItemInput!
      ) {
        response: completeOrganizationOnboardingChecklistItem(input: $input) {
          node {
            id
            completedAt
            ...AdminOnboardingChecklistItemTemplateFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationOnboardingChecklistItemId: "",
      actionSource: "automatic",
    }
  )

  const hideChecklistForm = useFormStore<OnboardingChecklistContextHideChecklistMutation>(
    graphql`
      mutation OnboardingChecklistContextHideChecklistMutation(
        $input: UpdateOrganizationMemberOnboardingChecklistVisibilityInput!
      ) {
        response: updateOrganizationMemberOnboardingChecklistVisibility(input: $input) {
          node {
            id
            hideOnboardingChecklist
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      hideOnboardingChecklist: true,
    }
  )

  const orderedSections = Relay.connectionToArray(node?.onboardingChecklistSections)
  // Flattened list of all sections' items ordered by their position in the checklist
  // This makes it easier to find the next incomplete item
  // Example: [section1.item1, section1.item2, section2.item1, section2.item2, ...]
  const orderedItems = orderedSections.flatMap((section) =>
    Relay.connectionToArray(section.items)
  )

  const completionPercentage =
    (orderedItems.filter((item) => item.completedAt).length / orderedItems.length) * 100
  // Find the next incomplete item in the checklist
  const nextIncompleteItem = orderedItems.find((item) => !item.completedAt) || null

  // Active state of the onboarding checklist
  const [activeState, setActiveState] = useState<OnboardingChecklistActiveState>({
    // Auto-expand the first incomplete section and item on initial render
    sectionId: nextIncompleteItem?.organizationOnboardingChecklistSectionId || null,
    itemId: nextIncompleteItem?.id || null,
  })

  const shouldHideChecklist =
    node?.viewerMembership?.hideOnboardingChecklist || !canRead || !orderedSections.length

  /**
   *  Set the active (organization) onboarding checklist section id in the context
   */
  const setActiveSectionId = useCallback(
    (id: string | null) => {
      // When a section is active, auto-expand the first incomplete item in the section
      const nextActiveItem = id
        ? orderedItems.find(
            (item) =>
              item.organizationOnboardingChecklistSectionId === id && !item.completedAt
          )
        : null

      setActiveState({
        sectionId: id,
        itemId: nextActiveItem ? nextActiveItem.id : null,
      })
    },
    [orderedItems]
  )

  /**
   * Set the active (organization) onboarding checklist item id in the context
   */
  const setActiveItemId = useCallback(
    (id: string | null) => {
      const activeItem = orderedItems.find((item) => item.id === id)

      setActiveState((prevState) => ({
        sectionId: activeItem
          ? activeItem.organizationOnboardingChecklistSectionId
          : prevState.sectionId,
        itemId: activeItem ? activeItem.id : null,
      }))
    },
    [orderedItems]
  )

  function isChecklistSectionCompletedOrMissing(kind: OnboardingChecklistSectionKind) {
    const section = orderedSections.find((s) => s.content.kind === kind)
    if (!section) return true

    return section.items.edges.every((item) => item.node.completedAt)
  }

  /**
   * Check if item of a given kind is completed
   */
  const isChecklistItemCompletedOrMissing = useCallback(
    (kind: OnboardingChecklistItemKind) => {
      const item = orderedItems.find((i) => i.content.kind === kind)
      if (!item) return true

      return Boolean(item.completedAt)
    },
    [orderedItems]
  )

  /**
   * Complete an onboarding checklist item by kind.
   * This will be ignored if the checklist item is already completed OR can not be completed by the viewer.
   */
  const completeOrIgnoreChecklistItem = useCallback(
    async (
      kind: OnboardingChecklistItemKind,
      actionSource: ReportingOnboardingChecklistActivityActionSource = "automatic"
    ) => {
      if (!canRead) return // Do nothing if the user does not have the required permission
      if (isChecklistItemCompletedOrMissing(kind)) return // Do nothing if the item is already completed or missing

      const isCompletingLastItem =
        orderedItems.filter((item) => !item.completedAt).length === 1

      const { didSave } = await form.submit({ kind, actionSource })
      if (!didSave) return

      // If the item is the last incomplete item, show a toast to indicate the completion of the checklist
      if (isCompletingLastItem) {
        displaySuccessToast({
          testid: "onboarding-checklist.completion-toast",
          message: "You've completed the onboarding checklist!",
        })
      }
    },
    [canRead, isChecklistItemCompletedOrMissing, form, orderedItems]
  )

  return (
    <OnboardingChecklistContext.Provider
      value={{
        activeState,
        sections: orderedSections,
        setActiveItemId,
        setActiveSectionId,
        completionPercentage,
        nextIncompleteItem,
        completeOrIgnoreChecklistItem,
        isChecklistSectionCompletedOrMissing,
        hideChecklistForm,
        shouldHideChecklist,
      }}
    >
      {children}
    </OnboardingChecklistContext.Provider>
  )
}

export default OnboardingChecklistProvider
