import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoDrawerProps, useCloseDrawer } from "@disco-ui"
import { DiscoDrawerHeaderAnchor } from "@disco-ui/drawer/DiscoDrawerHeader"
import useDisclosure from "@utils/hook/useDisclosure"
import React, { createContext, useContext, useRef, useState } from "react"
import { generatePath } from "react-router-dom"

/** If true, the page is displayed in a drawer  */
export const DrawerContext = createContext<{
  isInDrawer: boolean
  closeDrawer?: () => void
  fullScreen?: boolean
  setFullScreen?: React.Dispatch<React.SetStateAction<boolean>>
  headerEl?: HTMLDivElement | null
  hideHeaderActions: boolean
  setHideHeaderActions: React.Dispatch<React.SetStateAction<boolean>>
  isDrawerSidebarOpen: boolean
  openDrawerSidebar: () => void
  closeDrawerSidebar: () => void
}>({
  isInDrawer: false,
  hideHeaderActions: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHideHeaderActions: () => {},
  isDrawerSidebarOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openDrawerSidebar: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeDrawerSidebar: () => {},
})

export const useDrawerContext = () => useContext(DrawerContext)

type ManualDrawerContextProps = Omit<DiscoDrawerProps, "open"> & {
  disableClose?: boolean
}

/**
 * Manually provide the drawer context when it is needed for content that is not actually wrapped by the DiscoDrawer component.
 * ie. on the webview content page we render the content of drawers as the page content and must wrap those components in this provider
 */
export const ManualDrawerContext: React.FC<ManualDrawerContextProps> = (props) => {
  const {
    children,
    onClose,
    fullScreen: _fullScreen,
    testid,
    drawerAnchorKey,
    disableClose = false,
  } = props

  const activeProduct = useActiveProduct()

  // LEGACY - stop using when all path drawers are global
  const closeDrawer = useCloseDrawer(
    activeProduct
      ? generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
          productSlug: activeProduct.slug,
        })
      : generatePath(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
  )
  const drawerRef = useRef<HTMLDivElement>(null)
  const [isDrawerScrolled, setIsDrawerScrolled] = useState(
    drawerRef.current?.scrollTop === 0
  )
  const [headerEl, setHeaderEl] = useState<HTMLDivElement | null>(null)
  const [hideHeaderActions, setHideHeaderActions] = useState(false)

  // Mobile drawer sidebar state
  const {
    isOpen: isDrawerSidebarOpen,
    onOpen: openDrawerSidebar,
    onClose: closeDrawerSidebar,
  } = useDisclosure()

  const [fullScreen, setFullScreen] = useState(_fullScreen ?? false)

  return (
    <DrawerContext.Provider
      value={{
        isInDrawer: true,
        closeDrawer: disableClose ? undefined : onClose || closeDrawer,
        fullScreen,
        setFullScreen,
        headerEl,
        hideHeaderActions,
        setHideHeaderActions,
        isDrawerSidebarOpen,
        openDrawerSidebar,
        closeDrawerSidebar,
      }}
    >
      <div>
        <DiscoDrawerHeaderAnchor
          key={drawerAnchorKey}
          setHeader={setHeaderEl}
          isDrawerScrolled={isDrawerScrolled}
        />
      </div>
      <div ref={drawerRef} data-testid={testid} onScroll={onScroll}>
        {children}
      </div>
    </DrawerContext.Provider>
  )

  function onScroll() {
    if (drawerRef.current) {
      setIsDrawerScrolled(Boolean(drawerRef.current.scrollTop))
    }
  }
}
