/**
 * @generated SignedSource<<605063a828b5b9a4ccb500ae60452906>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StreamChatContext_ChannelsQuery$variables = {
  id: string;
};
export type StreamChatContext_ChannelsQuery$data = {
  readonly organization: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"StreamChatContext_ChannelsFragment">;
  } | null;
};
export type StreamChatContext_ChannelsQuery = {
  response: StreamChatContext_ChannelsQuery$data;
  variables: StreamChatContext_ChannelsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "navSectionId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StreamChatContext_ChannelsQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StreamChatContext_ChannelsFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StreamChatContext_ChannelsQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeProductLevel",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "kinds",
                    "value": [
                      "default",
                      "custom",
                      "direct_message"
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": {
                      "direction": "DESC",
                      "field": "last_message_sent_at"
                    }
                  }
                ],
                "concreteType": "ChatChannelNodeConnection",
                "kind": "LinkedField",
                "name": "chatChannels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChatChannelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChatChannel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "externalChannelId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductMembership",
                                "kind": "LinkedField",
                                "name": "viewerMembership",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductApp",
                            "kind": "LinkedField",
                            "name": "app",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "chatChannels(includeProductLevel:true,kinds:[\"default\",\"custom\",\"direct_message\"],orderBy:{\"direction\":\"DESC\",\"field\":\"last_message_sent_at\"})"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "409faae1d6cdd402a87e08a9ebfdc314",
    "id": null,
    "metadata": {},
    "name": "StreamChatContext_ChannelsQuery",
    "operationKind": "query",
    "text": "query StreamChatContext_ChannelsQuery(\n  $id: ID!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      id\n      ...StreamChatContext_ChannelsFragment\n    }\n    id\n  }\n}\n\nfragment StreamChatContext_ChannelsFragment on Organization {\n  chatChannels(kinds: [default, custom, direct_message], includeProductLevel: true, orderBy: {field: \"last_message_sent_at\", direction: DESC}) {\n    edges {\n      node {\n        id\n        productId\n        externalChannelId\n        kind\n        appId\n        product {\n          navSectionId\n          viewerMembership {\n            id\n          }\n          id\n        }\n        app {\n          navSectionId\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8d7a3c67f0b5b4848ae35620b3a50b4f";

export default node;
