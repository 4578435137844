/**
 * @generated SignedSource<<c43286958b31783a28a928974e3f22aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LabelKind = "admin_experience" | "admin_member" | "assignment" | "curriculum" | "custom" | "experience" | "lesson" | "organization_admin" | "organization_member" | "organization_owner" | "pathway" | "product_admin" | "product_instructor" | "product_member" | "quiz" | "sidebar_community" | "sidebar_product" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LabelsContext_Label$data = {
  readonly description: string;
  readonly kind: LabelKind;
  readonly plural: string;
  readonly singular: string;
  readonly " $fragmentType": "LabelsContext_Label";
};
export type LabelsContext_Label$key = {
  readonly " $data"?: LabelsContext_Label$data;
  readonly " $fragmentSpreads": FragmentRefs<"LabelsContext_Label">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LabelsContext_Label",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "singular",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plural",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Label",
  "abstractKey": null
};

(node as any).hash = "e01afca3d94d618d229142224d343c26";

export default node;
