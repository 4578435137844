import AdminConsoleFlows from "@/admin/AdminConsoleFlows"
import { AppLevelProvider } from "@/apps/util/appLevelContext"
import { useWebViewMessage } from "@/apps/util/hooks/useWebViewMessage"
import ChatFlows from "@/chat/ChatFlows"
import CommunityFlows from "@/community/flows/CommunityFlows"
import ActiveOrganizationProvider, {
  useActiveOrganization,
} from "@/core/context/ActiveOrganizationContext"
import {
  ActiveProductQueryProvider,
  ActiveProductStateProvider,
  useActiveProduct,
} from "@/core/context/ActiveProductContext"
import AuthUserProvider, { useAuthUser } from "@/core/context/AuthUserContext"
import { ConfettiProvider } from "@/core/context/ConfettiContext"
import ContextProviders from "@/core/context/ContextProviders"
import CustomThemeProvider from "@/core/context/CustomThemeProvider"
import { FullStoryProvider } from "@/core/context/FullStoryContext"
import { GlobalDrawerProvider } from "@/core/context/GlobalDrawerProvider"
import { GlobalModalProvider } from "@/core/context/GlobalModalProvider"
import LabelsProvider from "@/core/context/LabelsContext"
import LiveEventBar from "@/core/context/LiveEventBar"
import NotificationsProvider from "@/core/context/NotificationsContext"
import OnboardingChecklistProvider from "@/core/context/OnboardingChecklistContext"
import OnboardingProvider from "@/core/context/OnboardingContext"
import { ProductsAdminLearnModeProvider } from "@/core/context/ProductsAdminLearnModeContext"
import SearchProvider from "@/core/context/SearchContext"
import SentryProvider from "@/core/context/SentryProvider"
import { StreamChatProvider } from "@/core/context/StreamChatContext"
import UpdatePrompt from "@/core/context/UpdatePrompt"
import { UserflowProvider } from "@/core/context/UserflowContext"
import { useInitHistoryStack } from "@/core/history/historyStack"
import { AppWithContextProvidersPreloadedQuery } from "@/core/root-app/AppWithContextProvidersQuery"
import {
  AppWithContextProvidersQuery,
  AppWithContextProvidersQuery$variables,
} from "@/core/root-app/__generated__/AppWithContextProvidersQuery.graphql"
import NotFoundPageContent from "@/core/route/component/not-found/NotFoundPageContent"
import PermissionedRoute from "@/core/route/permissioned-route/PermissionedRoute"
import ROUTE_NAMES, {
  ADMIN_ROUTE_PREFIX,
  ONBOARDING_ROUTE_PREFIX,
} from "@/core/route/util/routeNames"
import MembershipSessionProvider from "@/core/session/MembershipSessionProvider"
import AppPageLayout from "@/main/page/AppPageLayout"
import AppHeaderLayout from "@/main/page/header/AppHeaderLayout"
import CookieConsentProvider from "@/organization/tracking/cookie-consent/CookieConsentStore"
import ProductFlows from "@/product/detail/flows/ProductFlows"
import useInitImpersonateTestUser from "@/product/util/hook/useInitImpersonateTestUser"
import useInitViewAsMember from "@/product/util/hook/useInitViewAsMember"
import useIsWebView from "@/product/util/hook/useIsWebView"
import LoadingPage from "@components/loading-page/LoadingPage"
import CreateTestUserPage from "@components/test-user/CreateTestUserPage"
import DeleteTestUserPage from "@components/test-user/DeleteTestUserPage"
import { ArrayUtils } from "@utils/array/arrayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { configure as configureMobx } from "mobx"
import { SnackbarProvider } from "notistack"
import { Suspense, useEffect } from "react"
import { HelmetProvider } from "react-helmet-async"
import { useQueryLoader, UseQueryLoaderLoadQueryOptions } from "react-relay"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import ScrollToTop from "../../components/scroll-to-top/ScrollToTop"
import { WindowSizeProvider } from "../../components/window-size/WindowSize"
import {
  getCommunityProtectedRouteComponents,
  getProtectedRouteComponents,
} from "../route/protectedRoutes"
import getPublicRouteComponents from "../route/publicRoutes"

export type LoadQueryProps = {
  loadQuery: (
    variables: AppWithContextProvidersQuery$variables,
    options?: UseQueryLoaderLoadQueryOptions
  ) => void
}

const BrainSearchFlows = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "brain-search-flows" */ "@/brain-search/internal/BrainSearchFlows"
    )
)

const OnboardingFlows = lazyWithRetry(
  () => import(/* webpackChunkName: "onboarding-flows" */ "@/onboarding/OnboardingFlows")
)

function AppWithContextProviders() {
  useInitImpersonateTestUser()
  useWebViewMessage()
  const isWebView = useIsWebView()
  useInitViewAsMember()

  const [queryReference, loadQuery] = useQueryLoader<AppWithContextProvidersQuery>(
    AppWithContextProvidersPreloadedQuery
  )
  useEffect(() => {
    loadQuery({ domain: window.location.origin })
  }, [loadQuery])

  return (
    <Suspense fallback={<LoadingPage />}>
      {queryReference != null && (
        <ContextProviders
          providers={[
            {
              Component: AuthUserProvider,
              props: { queryRef: queryReference },
            },
            HelmetProvider,
            SnackbarProvider,
            ScrollToTop,
            WindowSizeProvider,
            ProductsAdminLearnModeProvider,
            {
              Component: ActiveOrganizationProvider,
              props: { queryRef: queryReference },
            },
            // Providers that require consent should be rendered after CookieConsentProvider
            ...(isWebView ? [] : [CookieConsentProvider]),
            // There's a bug with Sentry interfering with Fullstory hooks so we must render sentry after Fullstory
            FullStoryProvider,
            SentryProvider,
            LabelsProvider,
            CustomThemeProvider,
            SearchProvider,
            ConfettiProvider, // relies on CustomThemeProvider
            ...(isWebView ? [] : [StreamChatProvider]),
            NotificationsProvider,
            ActiveProductStateProvider, // provides the ActiveProductContext value
            MembershipSessionProvider,
            OnboardingChecklistProvider,
            GlobalDrawerProvider,
            GlobalModalProvider,
            AppPageLayout, // suspense boundary for ActiveProductQueryProvider
            ActiveProductQueryProvider, // runs the ActiveProductContextQuery
            UserflowProvider,
          ]}
        >
          <RootAppFlows loadQuery={loadQuery} />
          {!isWebView && <UpdatePrompt />}
        </ContextProviders>
      )}
    </Suspense>
  )
}

configureMobx({
  // Allow setting observables outside of an action.
  // An action should be used to tune performance when updating multiple observables.
  enforceActions: "never",
})

function RootAppFlows(props: LoadQueryProps) {
  // Separate component from RootApp to access active org context
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const history = useHistory()
  const location = useLocation()
  const { authUser } = useAuthUser()
  const { brainSearch } = useFeatureFlags()

  const isWebView = useIsWebView()
  useInitHistoryStack()

  const noRedirectPaths: string[] = [
    // If we are on the logout page let the route go through and don't redirect them anywhere
    ROUTE_NAMES.AUTHENTICATION.LOGOUT,
    // If we are on the social auth redirect path, don't redirect
    ROUTE_NAMES.AUTHENTICATION.SOCIAL_AUTH_REDIRECT,
    // If we are on the exchange token path, don't redirect
    ROUTE_NAMES.AUTHENTICATION.TOKEN_EXCHANGE,
    // If we are on the sign up page, don't redirect
    ROUTE_NAMES.ONBOARDING.SIGN_UP,
    // If we are on redirecting for SSO, don't redirect
    ROUTE_NAMES.AUTHENTICATION.SSO_AUTH,
    // If we are on the e2e preload page, don't redirect
    ROUTE_NAMES.E2E_PRELOAD,
    // If we are in onboarding, don't redirect
    ...(Object.values(ROUTE_NAMES.ONBOARDING.V2).map((route) => route) as string[]),
  ]

  useEffect(() => {
    if (noRedirectPaths.includes(location.pathname)) {
      return
    }

    // Check if we are on the main page of the app and whether we need to push them to login
    // or put them through the community onboarding/registration (or let this condition fallthrough
    // to show the landing page). This check is important otherwise users joining new orgs might get
    // pushed to a step in the sign-up flow instead of seeing the checkout confirmation page.
    if (window.location.origin === BASE_DOMAIN_URL) {
      if (!authUser) {
        if (location.pathname === ROUTE_NAMES.ROOT)
          history.push(ROUTE_NAMES.AUTHENTICATION.LOGIN)

        // If a user doesn't have an organization and is not verifying their email, redirect
      } else if (
        !authUser.lastActiveOrganizationMembership &&
        !window.location.pathname.includes(ROUTE_NAMES.AUTHENTICATION.VERIFY_EMAIL)
      ) {
        // if a user doesn't belong to a community yet, bring them to the appropriate step in sign-up flow
        history.replace(ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED)
      }
    }

    const isOrganizationMember = Boolean(activeOrganization?.viewerMembership)
    // If user is an organization member, redirect them
    // from the Community Marketing Page('/') -> Community Home ('/home')
    if (
      isOrganizationMember &&
      location.pathname === ROUTE_NAMES.COMMUNITY.LANDING_PAGE.SITE
    ) {
      history.replace(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Switch>
        {[
          ...getPublicRouteComponents(props),
          ...getProtectedRouteComponents(activeOrganization),
          ...getCommunityProtectedRouteComponents(),
        ]}

        <Route key={"OnboardingFlows"} path={ONBOARDING_ROUTE_PREFIX}>
          <OnboardingProvider>
            <OnboardingFlows />
          </OnboardingProvider>
        </Route>

        {/* Only render pages within the community when a slug is present and activeOrganization found */}
        {activeOrganization && [
          /* Admin-only community pages */
          <PermissionedRoute
            key={"AdminConsoleFlows"}
            path={ADMIN_ROUTE_PREFIX}
            permission={["organization_settings.manage", "products_reports.read"]}
            entityKey={activeOrganization}
          >
            <AdminConsoleFlows />
          </PermissionedRoute>,
          /* All product-level flows */
          <Route key={"ProductFlows"} path={ROUTE_NAMES.PRODUCT.ROOT}>
            <AppLevelProvider productKey={activeProduct}>
              <ProductFlows />
            </AppLevelProvider>
          </Route>,
          /* Global chat tab flows */
          <Route key={"ChatFlows"} path={ROUTE_NAMES.CHAT.ROOT}>
            <ChatFlows />
          </Route>,

          /* Global Brain Search tab flows */
          ...ArrayUtils.spreadIf(
            <Route key={"BrainSearch"} path={ROUTE_NAMES.BRAIN.ROOT}>
              <BrainSearchFlows />
            </Route>,
            brainSearch
          ),
          /** Loading States */
          <PermissionedRoute
            key={"delete-ul"}
            path={ROUTE_NAMES.CREATE_TEST_USER}
            permission={"test_users.manage"}
            entityKey={activeOrganization}
          >
            <CreateTestUserPage />
          </PermissionedRoute>,
          <PermissionedRoute
            key={"delete-ul"}
            path={ROUTE_NAMES.DELETE_TEST_USER}
            permission={"test_users.manage"}
            entityKey={activeOrganization}
          >
            <DeleteTestUserPage />
          </PermissionedRoute>,
          /* Community-level pages for admins + members */
          <Route key={"CommunityFlows"} path={"/"}>
            <CommunityFlows />
          </Route>,
        ]}
        {/**
         * Exceptionally if a product landing page doesn't exist, the header needs to be rendered by this component
         * This is because the LandingPageEditor is responsible for rendering its own AppHeaderLayout, but no product level routes are rendered if the active product or organization isn't found
         */}
        <Route
          path={ROUTE_NAMES.PRODUCT.LANDING_PAGE.SITE}
          exact
          component={() => (
            <>
              <AppHeaderLayout showCommunityLogo />
              <NotFoundPageContent />
            </>
          )}
        />
        <Route component={NotFoundPageContent} />
      </Switch>
      {authUser && activeOrganization?.viewerMembership && !isWebView && <LiveEventBar />}
    </>
  )
}

export default AppWithContextProviders
