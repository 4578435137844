import { useAuthUser } from "@/core/context/AuthUserContext"
import { useCookieConsent } from "@/organization/tracking/cookie-consent/CookieConsentStore"
import { useTrackingTransparency } from "@/product/util/hook/useTrackingTransparency"
import Relay from "@/relay/relayUtils"
import * as FS from "@fullstory/browser"
import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"

const FullStoryContext = React.createContext(false)

const FullStoryProvider: React.FC = ({ children }) => {
  const { authUser } = useAuthUser()
  const { hasConsented } = useCookieConsent()
  const canTrack = useTrackingTransparency()
  const initialized = useRef(false)

  const shouldInitializeFullstory =
    !!FULLSTORY_ORG_ID &&
    !/headless/i.test(navigator.userAgent) &&
    hasConsented &&
    canTrack

  useEffect(() => {
    // initialize full story if the user has consented to cookies
    if (shouldInitializeFullstory && !initialized.current) {
      FS.init({ orgId: FULLSTORY_ORG_ID })
      initialized.current = true
    }
  }, [shouldInitializeFullstory])

  useEffect(() => {
    // if auth user changes, ensure we re-identify the auth-user
    if (authUser?.id && shouldInitializeFullstory) FS.identify(Relay.rawId(authUser.id))
  }, [authUser?.id, shouldInitializeFullstory])

  return (
    <>
      {shouldInitializeFullstory && (
        <Helmet>
          <script>
            {`
          !function(t,e){var n=new WeakMap;t._fs_prehooks=n,e.forEach((function(t){var e=t[0];t[1].forEach((function(t){!function(t,e,n){
if(t&&"function"==typeof t[e]){n.get(t)||n.set(t,{});var o=t[e];t[e]=function(){var f=n.get(t)
;return f&&"function"==typeof f[e]&&f[e].apply(this,arguments),o.apply(this,arguments)}}}(e,t,n)}))}))
}(window,[[CSSStyleSheet.prototype,["insertRule","removeRule"]]]);
        `}
          </script>
        </Helmet>
      )}

      <FullStoryContext.Provider value={shouldInitializeFullstory}>
        {children}
      </FullStoryContext.Provider>
    </>
  )
}

export { FullStoryContext, FullStoryProvider }
