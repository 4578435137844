import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { AIChatContextQuery } from "@/core/context/__generated__/AIChatContextQuery.graphql"
import { AIChatContext_ChannelsFragment$key } from "@/core/context/__generated__/AIChatContext_ChannelsFragment.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ArrayUtils } from "@utils/array/arrayUtils"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { Channel, DefaultGenerics, StreamChat } from "stream-chat"
import { Chat, DefaultStreamChatGenerics, useChatContext } from "stream-chat-react"
const STREAM_CHAT_CHANNELS_FETCH_LIMIT = 30 // default = 10, max = 30
const STREAM_CHAT_MESSAGES_FETCH_LIMIT = 100 // default = 25, max = 300

type ChatChannel = {
  id: GlobalID
  externalChannelId: string
  isDefaultSuggestion: boolean
}

export type AIChatContextValue = {
  isConnected: boolean
  setIsConnected: React.Dispatch<React.SetStateAction<boolean>>
  streamChannels: Channel<DefaultStreamChatGenerics>[]
  customChannels: ChatChannel[]
  suggestionsChannels: ChatChannel[]
  setStreamChannels: React.Dispatch<
    React.SetStateAction<Channel<DefaultStreamChatGenerics>[]>
  >
}
const AIChatContext = React.createContext({
  customChannels: [] as ChatChannel[],
  suggestionsChannels: [] as ChatChannel[],
} as AIChatContextValue)

export function useAIChat() {
  return useContext(AIChatContext)
}

/** Sets the StreamChat SDK in context when it is connected. */
export const AIChatProvider: React.FC = (props) => {
  const activeOrganization = useActiveOrganization()
  const [chatClient, setChatClient] = useState<StreamChat<DefaultGenerics> | null>(null)
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [streamChannels, setStreamChannels] = useState<
    Channel<DefaultStreamChatGenerics>[]
  >([])

  const { organization } = Relay.useBackgroundQuery<AIChatContextQuery>(
    graphql`
      query AIChatContextQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            id
            streamChatTeamId
            isChatBotEnabled
            viewerMembership {
              streamChatUserId
              streamChatUserToken
            }
            ...AIChatContext_ChannelsFragment
          }
        }
      }
    `,
    { id: activeOrganization?.id ?? "" }
  )

  // Separate fragment so it can be re-used elsewhere to update store
  const orgWithChannels = useFragment<AIChatContext_ChannelsFragment$key>(
    graphql`
      fragment AIChatContext_ChannelsFragment on Organization {
        chatChannels(kinds: [chat_bot]) {
          edges {
            node {
              id
              externalChannelId
              isDefaultSuggestion
            }
          }
        }
      }
    `,
    organization
  )

  const teamId = organization?.streamChatTeamId
  const userId = organization?.viewerMembership?.streamChatUserId
  const userToken = organization?.viewerMembership?.streamChatUserToken
  const isWebView = useIsWebView()

  const allChatBotChannels = Relay.connectionToArray(orgWithChannels?.chatChannels)

  // Connect/disconnect as organization and authUser change
  const hasCredentials = Boolean(teamId && userId && userToken)
  const shouldNotConnect = isWebView || !hasCredentials || !organization?.isChatBotEnabled

  useEffect(() => {
    if (shouldNotConnect) return

    const streamChat = new StreamChat(STREAM_API_KEY, { timeout: 10000 })
    streamChat
      .connectUser({ id: userId!, subdomain: SUBDOMAIN }, userToken)
      .then(() => setIsConnected(true))
    setChatClient(streamChat)

    return () => {
      chatClient?.disconnectUser()
      setIsConnected(false)
      setChatClient(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, userId, userToken, shouldNotConnect])

  const hasQueriedRef = useRef(false)

  const externalChatChannelIds = allChatBotChannels.map((occ) => occ.externalChannelId)

  useEffect(() => {
    // Don't run if the client isn't connected yet
    if (!isConnected || !chatClient) return
    // Don't run if we don't have any chat channels
    if (!externalChatChannelIds.length) return
    // Only run once when ready to
    if (hasQueriedRef?.current) return
    hasQueriedRef.current = true

    let chunkQueries: Promise<Channel<DefaultStreamChatGenerics>[]>[] = []

    // Chunk the channel ids to be fetch in separate requests
    const chunks = ArrayUtils.chunks(
      externalChatChannelIds,
      STREAM_CHAT_CHANNELS_FETCH_LIMIT
    )

    const queryStreamChannels = (streamChatChannelIds: string[]) => {
      return chatClient.queryChannels(
        { id: { $in: streamChatChannelIds.filter(Boolean) } },
        {},
        {
          limit: STREAM_CHAT_CHANNELS_FETCH_LIMIT,
          message_limit: STREAM_CHAT_MESSAGES_FETCH_LIMIT,
          watch: true,
        }
      )
    }

    chunkQueries = chunkQueries.concat(chunks.map(queryStreamChannels))

    if (!chunkQueries.length) return
    // Fetch the channels from stream
    Promise.all(chunkQueries).then((result) => {
      setStreamChannels((prev) => [...prev, ...result.flat()])
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, chatClient])

  // Sort chat channels by type
  const customChannels = []
  const suggestionsChannels = []
  for (const cc of allChatBotChannels) {
    if (cc.isDefaultSuggestion) {
      suggestionsChannels.push(cc)
    } else {
      customChannels.push(cc)
    }
  }

  return (
    <AIChatContext.Provider
      value={{
        isConnected,
        setIsConnected,
        streamChannels: streamChannels.filter(Boolean),
        setStreamChannels,
        customChannels,
        suggestionsChannels,
      }}
    >
      {chatClient ? (
        <Chat client={chatClient} theme={""}>
          {props.children}
        </Chat>
      ) : (
        props.children
      )}
    </AIChatContext.Provider>
  )
}

export function useAddAIChannelToContext() {
  const { client } = useChatContext()
  const { setStreamChannels } = useAIChat()

  return useCallback(
    (externalChatChannelId: string) => {
      // No client means this is the first channel initializing chat.
      // We don't need to query as it will be fetched by the context
      // provider when connecting to stream
      if (!client) return

      client
        .queryChannels({ id: externalChatChannelId }, {}, { watch: true })
        .then((result) => {
          const newStreamChannel = result[0]
          if (!newStreamChannel) return // Channel wasn't found so exit
          setStreamChannels((prev) => [...prev, newStreamChannel])
          return newStreamChannel
        })
    },
    [client, setStreamChannels]
  )
}

/** Get StreamChat Channel object for the given ID. Will return null if not connected. */
export function useAIChannel(
  channelId: string | null | undefined
): Channel<DefaultStreamChatGenerics> | null | undefined {
  const addStreamChannelToContext = useAddAIChannelToContext()
  const { streamChannels } = useAIChat()
  if (!channelId || !streamChannels.length) return null

  const streamChannel = streamChannels.find((sc) => sc.id === channelId)

  // If can not find the stream channel, add it to the store
  if (!streamChannel) {
    addStreamChannelToContext(channelId)
  }

  return streamChannel
}

/** Get StreamChat Channel objects for the given IDs. Will return an empty array if no channels were found. */
export function useAIChannels(
  channelIds: string[] | null | undefined
): Channel<DefaultStreamChatGenerics>[] | null | undefined {
  const addStreamChannelToContext = useAddAIChannelToContext()
  const { streamChannels } = useAIChat()
  const [channels, setChannels] = useState<Channel<DefaultStreamChatGenerics>[]>([])

  useEffect(() => {
    const missingChannels: Channel<DefaultStreamChatGenerics>[] = []
    if (!channelIds?.length || !streamChannels.length) return
    if (channelIds.length === channels.length) return

    for (const channelId of channelIds) {
      // Channel is already in the "channels" state
      const existingChannel = channels.find((c) => c.id === channelId)

      // Channel is missing from the "channels" state
      if (!existingChannel) {
        const streamChannel = streamChannels.find((sc) => sc.id === channelId)

        if (streamChannel) {
          missingChannels.push(streamChannel)
        } else {
          addStreamChannelToContext(channelId)
        }
      }
    }

    // Add the missing streamChannels to the "channels" state if not empty
    if (missingChannels.length) setChannels((prev) => [...prev, ...missingChannels])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamChannels, channelIds])

  return channels
}
