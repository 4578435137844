import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ConnectStripeModal from "@/payment/stripe-integration/modal/ConnectStripeModal"
import UpgradeCheckoutVersionModal from "@/payment/stripe-integration/upgrade/UpgradeCheckoutVersionModal"
import { QueryParamAction, useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import React, { useCallback } from "react"

type ModalOpenParam = "1"

// UpgradeCheckoutVersionModal
type UpgradeCheckoutVersionModalStep = "products" | "discounts"

/** Renders all global modals triggered via query params. It is the responsibility of each modal to open/close itself based on params */
export const GlobalModalProvider: React.FC = ({ children }) => {
  const activeOrganizaton = useActiveOrganization()

  // Don't render the global modals outside of activeOrganization. If you set modal
  // query params but not the proper domain we don't want to render the modal since
  // the modal contents expect an activeOrganization set.
  if (!activeOrganizaton) return <>{children}</>

  return (
    <>
      {Object.entries(GLOBAL_MODALS).map(([key, { Component }]) => (
        <Component key={key} />
      ))}
      {children}
    </>
  )
}

/**
 * The config for all modals
 *
 * The key is the name of the query param that will trigger the modal
 *
 * Component: The component to render
 * params: default params for the modal, all params with defaults of empty strings are required for modal to be open
 * optionalParams: The params that are optional for the passing extra information to the modal, not included in the default query params
 * openForAnyParams: The params that if any are present, the modal should open
 */

const GLOBAL_MODALS = {
  connectStripe: {
    Component: ConnectStripeModal,
    params: {
      connectStripeModal: "" as ModalOpenParam,
    } as {
      connectStripeModal: ModalOpenParam
    },
    optionalParams: [],
  },
  upgradeCheckoutVersion: {
    Component: UpgradeCheckoutVersionModal,
    params: {
      upgradeCheckoutVersionModal: "" as ModalOpenParam,
      step: "products",
    } as {
      upgradeCheckoutVersionModal: ModalOpenParam
      step: UpgradeCheckoutVersionModalStep
    },
    optionalParams: [],
  },
}

/** The config for all modals */
export type GlobalModalsConfig = typeof GLOBAL_MODALS

/** All available modal names */
export type GlobalModalKind = keyof GlobalModalsConfig

/** The params interface for a given modal */
export type GlobalModalParams<K extends GlobalModalKind> = GlobalModalsConfig[K]["params"]

/** Used to access methods for dealing with a global modal */
export function useGlobalModal<K extends GlobalModalKind>(kind: K) {
  const [params, setParams] = useQueryParamState<GlobalModalsConfig[K]["params"]>()
  const optionalParamsKeys = GLOBAL_MODALS[kind].optionalParams || []

  const requiredParams = Object.entries(GLOBAL_MODALS[kind].params).map(
    ([p]) => p
  ) as (keyof GlobalModalParams<K>)[]

  const handleClose = useCallback(() => {
    const paramsKeys = Object.keys(GLOBAL_MODALS[kind].params)
    const allParamsKeys = [...paramsKeys, ...optionalParamsKeys]
    setParams(
      // Empty all param values for the modal.
      allParamsKeys.reduce((acc, param) => ({ ...acc, [param]: undefined }), {})
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kind, setParams])

  const openForAnyParams = (GLOBAL_MODALS[kind] as any).openForAnyParams || []
  const openForAny = openForAnyParams.map(
    (p: keyof GlobalModalParams<K>) => p as keyof GlobalModalParams<K>
  )
  const isOpen = openForAny.length
    ? openForAny.some((p: keyof GlobalModalParams<K>) => Boolean(params[p]))
    : requiredParams.every((p) => Boolean(params[p]))

  // Swap from one global modal to another. Avoids a bug when using close and open
  // in quick succession where the closed modal re-opens immediately.
  // NOTE: might be unnecessary now after useQueryParamState was changed to use window.location
  const handleSwap = useCallback(
    <NewK extends GlobalModalKind>(openParams: GlobalModalsConfig[NewK]["params"]) => {
      // Set the new modal kind's params and unset the old kind's params
      const newParams: GlobalModalsConfig[NewK]["params"] &
        GlobalModalsConfig[K]["params"] = openParams
      const oldKeys = Object.keys(GLOBAL_MODALS[kind].params)
      const allOldKeys = [...oldKeys, ...optionalParamsKeys]

      setParams(
        allOldKeys.reduce((acc, key) => ({ ...acc, [key]: undefined }), newParams)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kind, setParams]
  )

  return {
    kind,
    params,
    isOpen,
    open: setParams,
    close: handleClose,
    swap: handleSwap,
    setParams: useCallback(
      (
        newParams: Partial<GlobalModalsConfig[K]["params"]>,
        action?: QueryParamAction
      ) => {
        setParams({ ...params, ...newParams }, action)
      },
      [params, setParams]
    ),
  }
}
