import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { StreamChatContext_ChannelsFragment$key } from "@/core/context/__generated__/StreamChatContext_ChannelsFragment.graphql"
import { StreamChatContext_ChannelsQuery } from "@/core/context/__generated__/StreamChatContext_ChannelsQuery.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ArrayUtils } from "@utils/array/arrayUtils"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { Channel, DefaultGenerics, StreamChat } from "stream-chat"
import {
  Chat,
  DefaultStreamChatGenerics,
  StreamMessage,
  UserItemProps,
  useChatContext,
} from "stream-chat-react"

const STREAM_CHAT_CHANNELS_FETCH_LIMIT = 30 // default = 10, max = 30
const STREAM_CHAT_MESSAGES_FETCH_LIMIT = 100 // default = 25, max = 300
// Stream rate limit is 60/user/endpoint/min. We want to be well under that so if the user
// navigates to and loads a specific channel, there's still room for that query to succeed
const STREAM_CHAT_CHANNELS_BATCH_LIMIT = 50
const STREAM_CHAT_RATE_LIMIT_RESET_MS = 61 * 1000 // 60s + 1s buffer

const chatClient = StreamChat.getInstance(STREAM_API_KEY, { timeout: 10000 })

type ChatChannel = {
  id: GlobalID
  externalChannelId: string
  productId: GlobalID | null
  appId: GlobalID | null
  navSectionId: GlobalID | null
}

export type StreamChatContextValue = {
  isConnected: boolean
  setIsConnected: React.Dispatch<React.SetStateAction<boolean>>
  streamChannels: Channel<DefaultStreamChatGenerics>[]
  directMessages: ChatChannel[]
  productChannels: ChatChannel[]
  productMemberChannels: ChatChannel[]
  communityChannels: ChatChannel[]
  chatClient: StreamChat<DefaultGenerics> | null
  setStreamChannels: React.Dispatch<
    React.SetStateAction<Channel<DefaultStreamChatGenerics>[]>
  >
  hasLoadedChannels: boolean
}
const StreamChatContext = React.createContext({
  directMessages: [] as ChatChannel[],
} as StreamChatContextValue)

export function useStreamChat() {
  return useContext(StreamChatContext)
}

/** Sets the StreamChat SDK in context when it is connected. */
export const StreamChatProvider: React.FC = (props) => {
  const activeOrganization = useActiveOrganization()
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [streamChannels, setStreamChannels] = useState<
    Channel<DefaultStreamChatGenerics>[]
  >([])
  const [hasLoadedChannels, setHasLoadedChannels] = useState<boolean>(false)

  const { organization } = Relay.useBackgroundQuery<StreamChatContext_ChannelsQuery>(
    graphql`
      query StreamChatContext_ChannelsQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            id
            ...StreamChatContext_ChannelsFragment
          }
        }
      }
    `,
    { id: activeOrganization?.id ?? "" }
  )

  // Separate fragment so it can be re-used elsewhere to update store
  const orgWithChannels = useFragment<StreamChatContext_ChannelsFragment$key>(
    graphql`
      fragment StreamChatContext_ChannelsFragment on Organization {
        chatChannels(
          kinds: [default, custom, direct_message]
          includeProductLevel: true
          # We want to query channels with most recent activity from Stream first so if we
          # hit the rate limit, the most important ones will be usable
          orderBy: { field: "last_message_sent_at", direction: DESC }
        ) {
          edges {
            node {
              id
              productId
              externalChannelId
              kind
              appId
              product {
                navSectionId
                viewerMembership {
                  id
                }
              }
              app {
                navSectionId
              }
            }
          }
        }
      }
    `,
    organization
  )

  const teamId = activeOrganization?.streamChatTeamId
  const userId = activeOrganization?.viewerMembership?.streamChatUserId
  const userToken = activeOrganization?.viewerMembership?.streamChatUserToken
  const isWebView = useIsWebView()

  const allChatChannels = Relay.connectionToArray(orgWithChannels?.chatChannels)

  // Connect/disconnect as organization and authUser change
  const hasCredentials = Boolean(teamId && userId && userToken)
  const shouldNotConnect =
    isWebView ||
    !hasCredentials ||
    (!activeOrganization?.isDmEnabled && !activeOrganization?.isChannelsEnabled)

  useEffect(() => {
    if (shouldNotConnect) return

    // Whether connectUser was interrupted
    let didUserConnectInterrupt = false
    const connectionPromise = chatClient
      .connectUser({ id: userId!, subdomain: SUBDOMAIN }, userToken)
      .then(() => {
        // If disconnectUser was called, don't try to reconnect the client
        if (!didUserConnectInterrupt) setIsConnected(true)
      })

    return () => {
      didUserConnectInterrupt = true
      setIsConnected(false)
      // Finish connecting the user before the clean up
      connectionPromise.then(() => chatClient.disconnectUser())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, userId, userToken, shouldNotConnect])

  const hasQueriedRef = useRef(false)

  useEffect(() => {
    // Don't run if the client isn't connected yet
    if (!isConnected) return
    // Wait for the channels query to finish loading in the background first
    if (!orgWithChannels?.chatChannels) return
    // Only run once when ready to
    if (hasQueriedRef.current) return
    hasQueriedRef.current = true

    // Query DM channels after regular channels since there can be an excessive number of
    // DMs created with automations
    const externalChatChannelIds = []
    const externalDMChannelIds = []
    for (const cc of allChatChannels) {
      if (cc.kind === "direct_message") {
        externalDMChannelIds.push(cc.externalChannelId)
      } else {
        externalChatChannelIds.push(cc.externalChannelId)
      }
    }

    // Chunk the channel ids to be fetch in separate requests
    // Avoid Stream rate limits by querying only some batches immediately
    const chunks = ArrayUtils.chunks(
      externalChatChannelIds,
      STREAM_CHAT_CHANNELS_FETCH_LIMIT
    )
    let limitChunks = chunks.splice(0, STREAM_CHAT_CHANNELS_BATCH_LIMIT)
    let chunkQueries = limitChunks.map((ids) => queryStreamChannels(ids))

    // Chunk the DM channel ids to be fetch in separate requests
    const dmChunks = ArrayUtils.chunks(
      externalDMChannelIds,
      STREAM_CHAT_CHANNELS_FETCH_LIMIT
    )
    limitChunks = dmChunks.splice(
      0,
      STREAM_CHAT_CHANNELS_BATCH_LIMIT - chunkQueries.length
    )
    chunkQueries = chunkQueries.concat(
      limitChunks.map((ids) => queryStreamChannels(ids, true))
    )

    if (!chunkQueries.length) {
      setHasLoadedChannels(true)
      return
    }

    // Wait for the channels to load from stream
    Promise.allSettled(chunkQueries)
      .then(handleChannelResults)
      .finally(() => setHasLoadedChannels(true))

    // Any remaining chunks will be queried periodically as the rate limit resets
    let delayedQueryTimeout: NodeJS.Timeout | undefined
    if (chunks.length || dmChunks.length) delayedQuery()
    function delayedQuery() {
      delayedQueryTimeout = setTimeout(() => {
        const isDm = !chunks.length
        limitChunks = (isDm ? dmChunks : chunks).splice(
          0,
          STREAM_CHAT_CHANNELS_BATCH_LIMIT
        )
        Promise.allSettled(limitChunks.map((ids) => queryStreamChannels(ids, isDm)))
          .then(handleChannelResults)
          .finally(() => {
            if (chunks.length || dmChunks.length) delayedQuery()
          })
      }, STREAM_CHAT_RATE_LIMIT_RESET_MS)
    }

    return () => clearTimeout(delayedQueryTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, orgWithChannels?.chatChannels])

  // Sort chat channels by type
  const directMessages = []
  const productChannels = []
  const productMemberChannels = []
  const communityChannels = []
  for (const cc of allChatChannels) {
    const channel: ChatChannel = {
      id: cc.id,
      externalChannelId: cc.externalChannelId,
      productId: cc.productId,
      appId: cc.appId,
      navSectionId: null,
    }
    if (cc.kind === "direct_message") {
      directMessages.push(channel)
    } else if (cc.productId) {
      if (cc.product?.navSectionId) {
        channel.navSectionId = cc.product.navSectionId
      }
      productChannels.push(channel)
      // Admins may be added to channels in products they are not members of
      // Get all product channels that the user is a member of the product for
      if (cc.product?.viewerMembership) productMemberChannels.push(channel)
    } else {
      if (cc.app?.navSectionId) {
        channel.navSectionId = cc.app.navSectionId
      }
      communityChannels.push(channel)
    }
  }

  return (
    <StreamChatContext.Provider
      value={{
        isConnected,
        setIsConnected,
        streamChannels: streamChannels.filter(Boolean),
        setStreamChannels,
        directMessages,
        productChannels,
        productMemberChannels,
        communityChannels,
        chatClient: shouldNotConnect ? null : chatClient,
        hasLoadedChannels,
      }}
    >
      <Chat client={chatClient} theme={""}>
        {props.children}
      </Chat>
    </StreamChatContext.Provider>
  )

  function queryStreamChannels(streamChatChannelIds: string[], isDm = false) {
    return chatClient.queryChannels(
      { id: { $in: streamChatChannelIds.filter(Boolean) } },
      {},
      {
        limit: STREAM_CHAT_CHANNELS_FETCH_LIMIT,
        message_limit: STREAM_CHAT_MESSAGES_FETCH_LIMIT,
        watch: true,
        // Only tracking presence for DM channels
        presence: isDm,
      }
    )
  }

  function handleChannelResults(
    results: PromiseSettledResult<Channel<DefaultStreamChatGenerics>[]>[]
  ) {
    let channels: Channel<DefaultStreamChatGenerics>[] = []
    for (const result of results) {
      if (result.status !== "fulfilled") continue
      channels = channels.concat(
        result.value.filter((sc) => !streamChannels.includes(sc))
      )
    }
    setStreamChannels((prev) => [...prev, ...channels])
  }
}

export function useAddStreamChannelToContext() {
  const { client } = useChatContext()
  const { setStreamChannels } = useStreamChat()

  return useCallback(
    (externalChatChannelId: string, isDM = false) => {
      // No client means this is the first channel initializing chat.
      // We don't need to query as it will be fetched by the context
      // provider when connecting to stream
      if (!client) return

      client
        .queryChannels({ id: externalChatChannelId }, {}, { watch: true, presence: isDM })
        .then((result) => {
          const newStreamChannel = result[0]
          if (!newStreamChannel) return // Channel wasn't found so exit
          setStreamChannels((prev) => [...prev, newStreamChannel])
          return newStreamChannel
        })
    },
    [client, setStreamChannels]
  )
}

function useAddStreamChannelsToContext() {
  const { client } = useChatContext()
  const { setStreamChannels } = useStreamChat()

  return useCallback(
    async (externalChatChannelIds: string[], isDm = false) => {
      if (!client) return []

      const newStreamChannels = await client.queryChannels(
        { id: { $in: externalChatChannelIds } },
        {},
        { watch: true, presence: isDm }
      )
      if (!newStreamChannels.length) return []

      setStreamChannels((prev) => [...prev, ...newStreamChannels])
      return newStreamChannels
    },
    [client, setStreamChannels]
  )
}

/** Get StreamChat Channel object for the given ID. Will return null if not connected. */
export function useStreamChannel(
  channelId: string | null | undefined,
  isDM = false
): Channel<DefaultStreamChatGenerics> | null | undefined {
  const addStreamChannelToContext = useAddStreamChannelToContext()
  const { streamChannels, hasLoadedChannels } = useStreamChat()
  if (!channelId || !hasLoadedChannels) return null

  const streamChannel = streamChannels.find((sc) => sc.id === channelId)

  // If can not find the stream channel, add it to the store
  if (!streamChannel) {
    addStreamChannelToContext(channelId, isDM)
  }

  return streamChannel
}

/** Get StreamChat Channel objects for the given IDs. Will return an empty array if no channels were found. */
export function useStreamChannels(
  channelIds: string[] | null | undefined,
  isDm = false
): Channel<DefaultStreamChatGenerics>[] {
  const addStreamChannelsToContext = useAddStreamChannelsToContext()
  const { streamChannels, hasLoadedChannels } = useStreamChat()
  const [channels, setChannels] = useState<Channel<DefaultStreamChatGenerics>[]>([])

  useEffect(() => {
    if (!channelIds?.length || !hasLoadedChannels) return

    const missingChannels: Channel<DefaultStreamChatGenerics>[] = []
    const channelIdsToFetch = []
    for (const channelId of channelIds) {
      // Channel is already in the "channels" state
      const existingChannel = channels.find((c) => c.id === channelId)

      // Channel is missing from the "channels" state
      if (!existingChannel) {
        const streamChannel = streamChannels.find((sc) => sc.id === channelId)

        if (streamChannel) {
          missingChannels.push(streamChannel)
        } else {
          channelIdsToFetch.push(channelId)
        }
      }
    }

    // Fetch any channels not yet loaded from Stream in a single batch
    if (channelIdsToFetch.length) addStreamChannelsToContext(channelIdsToFetch, isDm)

    // Add the missing streamChannels to the "channels" state if not empty
    if (missingChannels.length) setChannels((prev) => [...prev, ...missingChannels])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamChannels, channelIds])

  return channels
}

/** Sort StreamChat Channel objects by unread count */
export function sortStreamChannelsByUnreads(
  channels: Channel<DefaultStreamChatGenerics>[] | undefined | null
): Channel<DefaultStreamChatGenerics>[] | undefined | null {
  return channels?.sort((a, b) => b.state.unreadCount - a.state.unreadCount)
}

/** The user object expected from stream chat. Ie. message.user or quotedMessage.user (message from useMessageContext) */
export type StreamChatUserData = StreamMessage<DefaultStreamChatGenerics>["user"] & {
  // Additional fields we added to the stream user object
  disco_user_id: string
  is_disco_test_user?: boolean
  avatar: string | null
  first_name: string | null
  last_name: string | null
  organization_membership_id: string
  itemNameParts: UserItemProps["entity"]["itemNameParts"]
}
